import "./src/styles/global.css";
import React from "react";
import { Helmet } from "react-helmet";
export const wrapPageElement = ({ element }) => {
  return (
    <>
      <Helmet>
        {/* <!-- OneTrust Cookies Consent Notice start for revitilife.com --> */}
        <script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          data-document-language="true"
          type="text/javascript"
          data-domain-script="38a1183f-fab2-416e-8cc5-3866e9568899"
        ></script>
        <script type="text/javascript">
          {`function OptanonWrapper() { }`}
        </script>
        {/* <!-- OneTrust Cookies Consent Notice end for revitilife.com --> */}
        {/* <!-- Google Tag Manager --> */}
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.g.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-W6LT4CM');`}
        </script>
        {/* <!-- End Google Tag Manager --> */}

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
        <meta name="author" content="name" />
        <meta name="description" content="description here" />
        <meta name="keywords" content="keywords,here" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700;800&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Karla:wght@400;600;700;800&display=swap"
          rel="stylesheet"
        />
        <link
          rel="shortcut icon"
          type="image/x-icon"
          href="/static/favicon/favicon.ico?"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/static/favicon/favicon-16x16.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/static/favicon/favicon-32x32.png"
        />
        <link
          rel="apple-touch-icon"
          type="image/png"
          href="/static/favicon/apple-touch-icon.png"
        />
        <link
          rel="apple-touch-icon"
          type="image/png"
          sizes="192x192"
          href="/static/favicon/android-chrome-192x192.png"
        />
        <link
          rel="apple-touch-icon"
          type="image/png"
          sizes="512x512"
          href="/static/favicon/android-chrome-512x512.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/static/favicon/favicon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="512x512"
          href="/static/favicon/favicon-512x512.png"
        />
        <meta
          name="msapplication-TileImage"
          content="./static/favicon/mstile-150x150.png"
        />
        <link rel="stylesheet" href="~/css/Icon-Card-Style-Sheet.css" />
        <link rel="stylesheet" href="~/css/output.css" />
        <script
          src="https://cdn.jsdelivr.net/gh/alpinejs/alpine@v1.9.8/dist/alpine.js"
          defer
        ></script>
        <script src="https://unpkg.com/swiper/swiper-bundle.min.js"></script>
      </Helmet>
      {element}
    </>
  );
};
