exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-amazon-incentive-tsx": () => import("./../../../src/pages/amazon_incentive.tsx" /* webpackChunkName: "component---src-pages-amazon-incentive-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-notice-tsx": () => import("./../../../src/pages/privacy-notice.tsx" /* webpackChunkName: "component---src-pages-privacy-notice-tsx" */),
  "component---src-pages-reviti-payback-terms-and-conditions-tsx": () => import("./../../../src/pages/reviti-payback-terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-reviti-payback-terms-and-conditions-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */)
}

